<template>
    <div class="grid justify-items-center">
        <FormKit
            type="button"
            @click="onShopClick"
        >
            {{ $t(label) }}
        </FormKit>
    </div>
</template>

<script setup lang="ts">

import useEnv from '@/composables/env'

withDefaults(
    defineProps<{
        label?: string
    }>(),
    {
        label: 'home.shop',
    }
)

const { shopUrl } = useEnv()

const onShopClick = () => {
    window.open(shopUrl, '_blank')
}
</script>
