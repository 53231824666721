<template>
    <p>{{ $t('admin.total_count', { number: totalCount }) }}</p>
    <p>{{ $t('admin.vegan_count', { number: veganCount })  }}</p>
    <p>{{ $t('admin.non_dutch_count', { number: nonDutchCount }) }}</p>
</template>

<script setup lang="ts">
import useRsvpCounter from '@/composables/rsvpCounter'

const { totalCount, veganCount, nonDutchCount } = useRsvpCounter()
</script>
