import { PositionEnum, SimpleToastMessages } from 'simple-toast-messages'

const POSITION = PositionEnum.BOTTOM_CENTER
const TIMEOUT = 3000

const useAlerts = () => {
    const messaging = SimpleToastMessages.getInstance()

    function success(message: string) {
        messaging.success(message, {
            timeOut: TIMEOUT,
            position: POSITION,
        })
    }
    function error(message: string) {
        messaging.error(message, {
            timeOut: TIMEOUT,
            position: POSITION,
        })
    }
    function info(message: string) {
        messaging.info(message, {
            timeOut: TIMEOUT,
            position: POSITION,
        })
    }
    function warning(message: string) {
        messaging.warning(message, {
            timeOut: TIMEOUT,
            position: POSITION,
        })
    }

    return {
        success,
        error,
        info,
        warning,
    }
}

export default useAlerts
