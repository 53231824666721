<template>
    <div>
        <h4>{{ $t('quickinfo.title') }}</h4>
        <table>
            <tbody>
                <tr>
                    <th>{{ $t('quickinfo.date') }}</th>
                    <td>{{ $t('quickinfo.date_value') }}</td>
                </tr>
                <tr>
                    <th>{{ $t('quickinfo.location') }}</th>
                    <td><a :href="locationUrl" target="_blank">Theater De Boemel, Tilburg</a></td>
                </tr>
                <tr>
                    <th>{{ $t('quickinfo.ceremony_start') }}</th>
                    <td>{{ $t('quickinfo.ceremony_start_value') }}</td>
                </tr>
                <tr>
                    <th>{{ $t('quickinfo.ceremony_end') }}</th>
                    <td>{{ $t('quickinfo.ceremony_end_value') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import useEnv from '@/composables/env'

const { locationUrl } = useEnv()
</script>
