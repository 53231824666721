import { defineStore } from 'pinia'
import type { Rsvp } from '@/types/Rsvp'
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { useAuthenticatedAxios } from '@/composables/axios'

const useRsvpStore = defineStore('rsvp', () => {
    const rsvps: Ref<Array<Rsvp>> = ref([])
    const count: Ref<number> = ref(0)

    onMounted(() => {
        useAuthenticatedAxios()
            .then((axios) => axios.get(route('api.admin.rsvp')))
            .then((response) => {
                rsvps.value = response.data
                count.value = rsvps.value
                    .map((rsvp: Rsvp) => rsvp.plusOne ? 2 : 1)
                    .reduce((sum: number, rsvpCount: number) => sum + rsvpCount, 0)
            })
    })

    return {
        rsvps,
        count,
    }
})

export default useRsvpStore
