<template>
    <h2>{{ $t('rsvp.picture_title') }}</h2>
    <FormKit
        type="form"
        v-model="formValues"
        :submit-labl="$t('rsvp.submit_picture')"
        @submit="onSubmit"
    >
        <FormKit
            type="file"
            name="files"
            :label="$t('rsvp.file')"
            accept=".jpg,.jpeg,.png,.tif,.tiff,.webp,.gif,.avif,.bmp"
            :multiple="true"
            :errors="errors.files"
        />
        <FormKit
            type="button"
            @click="onBack"
        >
            {{ $t('rsvp.back') }}
        </FormKit>
        <FormKit
            type="button"
            @click="onSkip"
        >
            {{ $t('rsvp.skip') }}
        </FormKit>
    </FormKit>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useAxios from '@/composables/axios'
import useAlerts from '@/composables/useAlerts'
import { trans } from 'laravel-vue-i18n'

const props = defineProps<{
    rsvpId: number | null
    canGoBack: boolean
}>()

const emit = defineEmits<{
    (e: 'submit')
    (e: 'back')
    (e: 'skip')
}>()

const { success, error } = useAlerts()

const formValues = ref({
    rsvp_id: props.rsvpId,
    files: [],
})

const errors = ref({})

const onBack = () => {
    emit('back')
}

const onSkip = () => {
    emit('skip')
}

const onSubmit = (data) => {
    const body = new FormData()
    body.append('rsvp_id', data.rsvp_id)

    data.files.forEach((file, index) => {
        body.append(`files[${index}]`, file.file)
    })

    useAxios()
        .then((axios) => {
            axios.post(route('api.picture'), body)
                .then(() => {
                    success(trans('rsvp.alert_success_picture'))
                    emit('submit')
                })
                .catch((message) => {
                    console.error(message)
                    error(trans('rsvp.alert_error'))
                    errors.value = message.response.data.errors
                })
        })
}
</script>
