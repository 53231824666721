import { createApp } from 'vue'
import App from '@/components/App.vue'
import { i18nVue } from 'laravel-vue-i18n'
import router from '@/router'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from '@formkit/vue'
import { en, nl } from '@formkit/i18n'
import { rootClasses } from '../../formkit.theme'
import { generateClasses } from '@formkit/themes'
import VueEasyLightbox from 'vue-easy-lightbox'
import { createAuth0 } from '@auth0/auth0-vue'

const main = createApp(App)
const pinia = createPinia()
main.use(pinia)

main.use(router)

main.use(VueEasyLightbox)

main.use(createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    },
    cacheLocation: 'localstorage',
}))

main.use(plugin, defaultConfig({
    locales: { en, nl },
    locale: 'nl',
    config: {
        rootClasses,
        classes: generateClasses({
            global: {
                outer: '$remove:text-base prose-xl',
                inner: '$remove:text-base',
                input: '$remove:text-sm $remove:!text-sm $remove:text-base prose prose-xl',
                label: '$remove:text-sm $remove:!text-sm',
                legend: '$remove:text-sm',
                fileItem: '$remove:text-base',
                message: '$text-base $remove:text-xs',
            },
        }),
    },
}))

main.use(i18nVue, {
    resolve: async (lang: string) => {
        const langs = import.meta.glob('../../lang/*.json')
        const language = lang.replace(/^(?:php_)+([a-z]+)$/, '$1')

        return await langs[`../../lang/php_${language}.json`]()
    }
})

main.mount('#app')
