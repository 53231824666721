<template>
    <layout title="pages.gift_suggestions">
        <p>{{ $t('gift_suggestion.honeymoon') }}</p>
        <p>{{ $t('gift_suggestion.wishlist') }}</p>
        <div class="grid justify-items-center">
            <FormKit
                type="button"
                @click="onWishListClick"
            >
                {{ $t('gift_suggestion.lijstje_link_text') }}
            </FormKit>
        </div>
    </layout>
</template>

<script setup lang="ts">
import Layout from '@/layout/Layout.vue'
import useEnv from '@/composables/env'

const { lijstjeUrl } = useEnv()

const onWishListClick = () => {
    window.open(lijstjeUrl, '_blank').focus()
}
</script>
