import axios from 'axios'
import type { Axios, CreateAxiosDefaults, AxiosError, AxiosResponse } from 'axios'
import { useAuth0 } from '@auth0/auth0-vue'

export default async function useAxios(config: CreateAxiosDefaults = {}): Promise<Axios> {
    let token: HTMLMetaElement  = document.head.querySelector('meta[name="csrf-token"]')

    config.headers = {
        ...(config.headers ?? {}),
        'X-CSRF-TOKEN': token.content,
    }

    return axios.create(config)
}

export async function useAuthenticatedAxios(config: CreateAxiosDefaults = {}): Promise<Axios> {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
    const accessToken = await getAccessTokenSilently()

    config.headers = {
        ...(config.headers ?? {}),
        Authorization: `Bearer  ${accessToken}`,
    }

    return useAxios(config)
        .then((axios: Axios) => {
            axios.interceptors.response.use(
                (response: AxiosResponse) => response,
                (error: AxiosError) => {
                    if (error.response.status === 403) {
                        loginWithRedirect()
                    } else {
                        Promise.reject(error)
                    }
                }
            )

            return axios
        })
}
