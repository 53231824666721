import { defineStore } from 'pinia'
import { computed, onBeforeMount, ref } from 'vue'
import { getActiveLanguage, loadLanguageAsync } from 'laravel-vue-i18n'
import type { Language } from '@/types/language'
import { changeLocale } from '@formkit/i18n'
import useAxios from '@/composables/axios'

const DEFAULT_LANGUAGE = 'nl'

const useLanguageStore = defineStore('language', () => {
    const language = ref<Language>(getActiveLanguage() as Language)

    function setLanguage(newLanguage: Language): void {
        language.value = newLanguage
        loadLanguageAsync(newLanguage)
        changeLocale(newLanguage)

        useAxios()
            .then((axios) => {
                axios.post(route('api.language'), {
                    language: newLanguage,
                })
            })
    }

    onBeforeMount(() => {
        language.value = getActiveLanguage() as Language
        changeLocale(getActiveLanguage())
    })

    const $reset = () => {
        language.value = DEFAULT_LANGUAGE
    }

    return {
        language: computed(() => language.value),
        setLanguage,
        $reset,
    }
})

export default useLanguageStore
