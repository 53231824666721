const locationUrl = import.meta.env.VITE_LOCATION_URL
const parking1Url = import.meta.env.VITE_PARKING_1_URL
const parking2Url = import.meta.env.VITE_PARKING_2_URL
const parking3Url = import.meta.env.VITE_PARKING_3_URL
const lijstjeUrl = import.meta.env.VITE_LIJSTJE_URL
const weddingDate = import.meta.env.VITE_WEDDING_DATE
const shopUrl = import.meta.env.VITE_SHOP_URL

export default function useEnv() {
    return {
        locationUrl,
        parking1Url,
        parking2Url,
        parking3Url,
        lijstjeUrl,
        weddingDate,
        shopUrl,
    }
}
