<template>
    <div
        :class="{ 'hamburger-lines': true, 'menu-collapsed': collapsed, 'menu-expanded': !collapsed, 'float-right': true, 'lg:hidden': true }"
        @click="toggleMenu"
    >
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
    </div>
    <div :class="{ 'menu-items': true, 'menu-collapsed': collapsed, 'menu-expanded': !collapsed }">
        <div class="languageswitcher">
            <FormKit
                type="select"
                name="language"
                :options="{
                    nl: 'Nederlands',
                    en: 'English',
                }"
                :value="language"
                @input="setLanguage"
                :classes="{
                    outer: {
                        prose: false,
                        'prose-xl': false,
                    },
                    input: {
                        prose: false,
                        'prose-xl': false,
                    },
                }"
            >
                <template #prefixIcon>
                    <img class="icon" src="@/../images/language.svg" :alt="$t('general.select_language')">
                </template>
            </FormKit>
        </div>
        <nav class="menu text-xl">
            <ul>
                <li><router-link to="/" @click="onClick">{{ $t('pages.home') }}</router-link></li>
                <li v-if="isWeddingDate"><a :href="shopUrl" target="_blank">{{ $t('pages.shop') }}</a></li>
                <li v-else><router-link to="/rsvp" @click="onClick">{{ $t('pages.rsvp') }}</router-link></li>
                <li><router-link to="/gift-suggestions" @click="onClick">{{ $t('pages.gift_suggestions') }}</router-link></li>
                <li><router-link to="/contact" @click="onClick">{{ $t('pages.contact') }}</router-link></li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import useLanguageStore from '@/stores/language'
import { ref } from 'vue'
import useWeddingDate from '@/composables/weddingDate'
import useEnv from '@/composables/env'

const { language, setLanguage } = useLanguageStore()
const { isWeddingDate } = useWeddingDate()
const { shopUrl } = useEnv()

const collapsed = ref(true)

const onClick = () => {
    collapsed.value = true
}
const toggleMenu = () => {
    collapsed.value = !collapsed.value
}
</script>
