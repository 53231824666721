<template>
    <div class="footer grid justify-center">
        <Tooltip>
            <button type="button" @click="onClick">
                <img src="@/../images/hand.svg" class="slapper" :alt="$t('general.bitch_slap')" />
            </button>

            <template #popper>
                {{ $t('general.bitch_slap') }}
            </template>
        </Tooltip>
    </div>
    <div v-if="isAuthenticated" class="footer grid justify-center">
        <router-link to="/admin">{{ user.email }}</router-link>
        <button @click="onLogout">Logout</button>
    </div>
</template>

<script setup lang="ts">
import { Tooltip } from 'floating-vue'
import { useAuth0 } from '@auth0/auth0-vue'

const { isAuthenticated, user, logout } = useAuth0()

const onClick = () => {
    document.body.classList.toggle('slapped')
}

const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin }})
}
</script>
