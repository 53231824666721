import { onMounted, ref } from 'vue'
import useEnv from '@/composables/env'

const isWeddingDate = ref(false)

const useWeddingDate = () => {
    const { weddingDate: weddingDateString } = useEnv()

    function calculateWeddingDate() {
        const weddingDate = new Date(weddingDateString)

        isWeddingDate.value = Date.now() >= weddingDate.getTime()
    }

    onMounted(() => {
        calculateWeddingDate()

        setInterval(calculateWeddingDate, 1000 * 60 * 5)
    })

    return {
        isWeddingDate,
    }
}

export default useWeddingDate
