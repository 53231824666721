<template>
    <FormKit
        type="form"
        v-model="formValues"
        class="prose prose-2xl"
        :submit-label="$t('rsvp.form_submit')"
        @submit="onSubmit"
    >
        <FormKit
            type="text"
            name="name"
            :label="$t('rsvp.name')"
            validation="required"
            :errors="errors.name"
        />
        <FormKit
            v-if="!plusOne"
            type="button"
            @click="plusOne = true"
        >
            {{ $t('rsvp.add_plus_one') }}
        </FormKit>
        <FormKit
            v-if="plusOne"
            type="text"
            name="plusOne"
            :label="$t('rsvp.plus_one')"
            :validation="plusOne ? 'required' : null"
            :errors="errors.plusOne"
        />
        <FormKit
            type="select"
            :options="dietOptions"
            :label="$t('rsvp.diet')"
            name="diet"
            validation="required"
            :errors="errors.diet"
        />
        <FormKit
            type="text"
            name="allergies"
            :label="$t('rsvp.allergies')"
            :errors="errors.allergies"
        />
        <FormKit
            type="checkbox"
            name="languages"
            :label="$t('rsvp.languages')"
            :options="languageOptions"
            :errors="errors.languages"
        />
    </FormKit>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { trans } from 'laravel-vue-i18n'
import useLanguageStore from '@/stores/language'
import useAxios from '@/composables/axios'
import useAlerts from '@/composables/useAlerts'
import { storeToRefs } from 'pinia'

const { success, error } = useAlerts()
const { language } = storeToRefs(useLanguageStore())

const emit = defineEmits<{
    (e: 'submit', rsvp: number)
}>()

const formValues = ref({
    name: '',
    plusOne: null,
    diet: 'vegetarian',
    allergies: null,
    languages: [language.value],
})
const plusOne = ref(false)

const errors = ref({})

const dietOptions = ref({})
const languageOptions = ref({})

const populateDietOptions = () => {
    dietOptions.value = {
        vegetarian: trans('rsvp.diet_vegetarian'),
        vegan: trans('rsvp.diet_vegan'),
    }
}
const populateLanguageOptions = () => {
    languageOptions.value = [
        { value: 'nl', label: trans('rsvp.language_nl') },
        { value: 'en', label: trans('rsvp.language_en') },
    ]
}

onMounted(() => {
    setTimeout(() => {
        populateDietOptions()
        populateLanguageOptions()
    }, 10)
})

watch(language, () => {
    populateDietOptions()
    populateLanguageOptions()
})

const onSubmit = (rsvp) => {
    useAxios()
        .then((axios) => {
            axios.post(route('api.rsvp'), rsvp)
                .then((response) => {
                    success(trans('rsvp.alert_success'))
                    emit('submit', response.data.rsvp)
                })
                .catch((message) => {
                    console.error(message)
                    error(trans('rsvp.alert_error'))
                    errors.value = message.response.data.errors
                })
        })
}
</script>
