<template>
    <layout title="pages.rsvp">
        <p>{{ $t('rsvp.intro') }}</p>
        <p>{{ $t('rsvp.playlist_and_photos') }}</p>
        <RsvpForm
            v-if="currentStep === Step.RSVP"
            @submit="onRsvpSubmit"
        />
        <PlaylistForm
            v-if="currentStep === Step.PLAYLIST"
            :rsvp-id="rsvpId"
            @submit="onPlaylistSubmit"
            @skip="onPlaylistSkip"
        />
        <PictureForm
            v-if="currentStep === Step.PICTURE"
            :rsvp-id="rsvpId"
            :can-go-back="!isPlaylistSubmitted"
            @submit="onPictureSubmit"
            @back="onPictureBack"
            @skip="onPictureSkip"
        />
        <p v-if="currentStep === Step.DONE">
            {{ $t('rsvp.done') }}
        </p>
    </layout>
</template>

<script setup lang="ts">
import Layout from '@/layout/Layout.vue'
import RsvpForm from '@/components/RsvpForm.vue'
import { ref } from 'vue'
import PictureForm from '@/components/PictureForm.vue'
import PlaylistForm from '@/components/PlaylistForm.vue'

enum Step {
    RSVP = 'rsvp',
    PLAYLIST = 'playlist',
    PICTURE = 'picture',
    DONE = 'done',
}

const currentStep = ref<Step>(Step.RSVP)

const rsvpId = ref<number | null>(null)
const isPlaylistSubmitted = ref(false)

const onRsvpSubmit = (rsvp: number) => {
    rsvpId.value = rsvp
    currentStep.value = Step.PLAYLIST
}
const onPlaylistSubmit = () => {
    isPlaylistSubmitted.value = true
    currentStep.value = Step.PICTURE
}
const onPlaylistSkip = () => {
    currentStep.value = Step.PICTURE
}
const onPictureSubmit = () => {
    currentStep.value = Step.DONE
}
const onPictureBack = () => {
    currentStep.value = Step.PLAYLIST
}
const onPictureSkip = () => {
    currentStep.value = Step.DONE
}
</script>
