<template>
    <div class="grid justify-items-center">
        <FormKit
            type="button"
            @click="onRsvpClick"
        >
            {{ $t(label) }}
        </FormKit>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

withDefaults(
    defineProps<{
        label?: string
    }>(),
    {
        label: 'home.rsvp',
    }
)

const router = useRouter()

const onRsvpClick = () => {
    router.push('/rsvp')
}
</script>
