<template>
    <layout title="pages.contact">
        <p>{{ $t('contact.intro') }}</p>
        <h3>{{ $t('contact.master_of_ceremonies') }}</h3>
        <dl>
            <dt>{{ $t('contact.name') }}</dt>
            <dd>Harry Mreijen</dd>
<!--            <dt>{{ $t('contact.phone') }}</dt>-->
<!--            <dd>-->
<!--                <a v-if="showPhone" href="tel:+31643211017">+31 (0)6 43 21 10 17</a>-->
<!--                <span v-else class="contact-reveal">-->
<!--                    <img-->
<!--                        src="@/../images/eye.svg"-->
<!--                        @click="revealPhone"-->
<!--                        :alt="$t('contact.reveal_phone')"-->
<!--                    />-->
<!--                </span>-->
<!--            </dd>-->
            <dt>{{ $t('contact.email') }}</dt>
            <dd>
                <a v-if="showEmail" href="mailto:h.mreijen@hotmail.com">h.mreijen@hotmail.com</a>
                <span v-else class="contact-reveal">
                    <img
                        src="@/../images/eye.svg"
                        @click="revealEmail"
                        :alt="$t('contact.reveal_email')"
                    />
                </span>
            </dd>
        </dl>
    </layout>
</template>

<script setup lang="ts">
import Layout from '@/layout/Layout.vue'
import { ref } from 'vue'

const showPhone = ref(false)
const showEmail = ref(false)

const revealPhone = () => {
    showPhone.value = true
}
const revealEmail = () => {
    showEmail.value = true
}
</script>
