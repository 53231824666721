import useRsvpStore from '@/stores/rsvp'
import type { Rsvp } from '@/types/Rsvp'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const useRsvpCounter = () => {
    const { rsvps } = storeToRefs(useRsvpStore())

    function countPeople(rsvps: Array<Rsvp>): number
    {
        return rsvps.map((rsvp: Rsvp): number => rsvp.plusOne ? 2 : 1)
            .reduce((sum: number, current: number): number => sum + current, 0)
    }

    const totalCount = computed(() => countPeople(rsvps.value))
    const veganCount = computed(() => countPeople(rsvps.value.filter((rsvp: Rsvp) => rsvp.diet === 'vegan')))
    const nonDutchCount = computed(() => countPeople(rsvps.value.filter((rsvp: Rsvp) => ! rsvp.languages.includes('nl'))))

    return {
        totalCount,
        veganCount,
        nonDutchCount,
    }
}

export default useRsvpCounter
