<template>
    <div v-if="pictures.length > 0">
        <button type="button" @click="showGallery">
            <img src="@/../images/gallery.svg" class="btn-img" :alt="$t('admin.gallery')"/>
            {{ pictures.length }}
        </button>
        <vue-easy-lightbox
            :visible="shown"
            :imgs="galleryUrls"
            :index="index"
            @hide="onHide"
        />
    </div>
</template>

<script setup lang="ts">
import type { Picture } from '@/types/Rsvp'
import { onBeforeUnmount, ref } from 'vue'
import { useAuthenticatedAxios } from '@/composables/axios'

const props = defineProps<{
    pictures: Array<Picture>
}>()

const shown = ref(false)
const index = ref(0)
const galleryUrls = ref([])

const axiosPromise = useAuthenticatedAxios({ responseType: 'blob' })

const fetchImages = async () => {
    if (galleryUrls.value.length > 0) {
        return
    }

    const urls = []
    return axiosPromise
        .then((axios) => Promise.all(
            props.pictures.map(
                (picture) => axios.get(route('api.admin.picture', { picture: picture.id }))
                    .then((response) => {
                        urls.push(URL.createObjectURL(response.data))
                    })
                )
            )
        ).then(() => {
            galleryUrls.value = urls
        })
}

const showGallery = async () => {
    await fetchImages()
    shown.value = true
}

const onHide = () => {
    shown.value = false
}

onBeforeUnmount(() => {
    galleryUrls.value.forEach((url) => URL.revokeObjectURL(url))
})
</script>
