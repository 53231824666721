<template>
    <table>
        <thead>
            <tr>
                <th>{{ $t('rsvp.name') }}</th>
                <th>{{ $t('admin.plus_one') }}</th>
                <th>{{ $t('rsvp.diet') }}</th>
                <th>{{ $t('rsvp.allergies') }}</th>
                <th>{{ $t('admin.languages') }}</th>
                <th>{{ $t('rsvp.playlist_title') }}</th>
                <th>{{ $t('admin.picture_count') }}</th>
                <th>{{ $t('admin.created_at') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="rsvp in rsvpStore.rsvps" :key="rsvp.id">
                <td>{{ rsvp.name }}</td>
                <td>{{ rsvp.plusOne }}</td>
                <td>{{ translateDiet(rsvp.diet) }}</td>
                <td>{{ rsvp.allergies }}</td>
                <td>{{ translateLanguages(rsvp.languages) }}</td>
                <td>{{ rsvp.playlist_suggestion ? (rsvp.playlist_suggestion.artist + ' - ' + rsvp.playlist_suggestion.track) : null }}</td>
                <td>
                    <PictureGallery :pictures="rsvp.pictures"/>
                </td>
                <td>{{ parseCreatedAt(rsvp.created_at) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import useRsvpStore from '@/stores/rsvp'
import type { Language } from '@/types/language'
import { trans } from 'laravel-vue-i18n'
import { format } from 'date-fns'
import PictureGallery from '@/components/PictureGallery.vue'

const rsvpStore = useRsvpStore()

const translateDiet = (diet: string): string => trans('rsvp.diet_' + diet)
const translateLanguages = (languages: Array<Language>): string => languages.map((language) => trans('rsvp.language_' + language)).join(', ')
const parseCreatedAt = (datestring: string): string => format(new Date(datestring), 'dd-MM-yyyy HH:mm')
</script>
