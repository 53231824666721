import { createRouter, createWebHistory } from 'vue-router'
import Contact from '@/pages/Contact.vue'
import Home from '@/pages/Home.vue'
import GiftSuggestions from '@/pages/GiftSuggestions.vue'
import Rsvp from '@/pages/Rsvp.vue'
import { authGuard } from '@auth0/auth0-vue'
import Admin from '@/pages/Admin.vue'

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/contact',
        component: Contact,
    },
    {
        path: '/gift-suggestions',
        component: GiftSuggestions,
    },
    {
        path: '/rsvp',
        component: Rsvp,
    },
    {
        path: '/admin',
        component: Admin,
        beforeEnter: authGuard,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
