<template>
    <h2>{{ $t('rsvp.playlist_title') }}</h2>
    <FormKit
        type="form"
        v-model="formValues"
        :submit-label="$t('rsvp.submit_playlist')"
        @submit="onSubmit"
    >
        <FormKit
            type="text"
            name="artist"
            :label="$t('rsvp.artist')"
            validation="required"
            :errors="errors.artist"
        />
        <FormKit
            type="text"
            name="track"
            :label="$t('rsvp.track')"
            validation="required"
            :errors="errors.track"
        />
        <FormKit
            type="button"
            @click="onSkip"
        >
            {{ $t('rsvp.skip') }}
        </FormKit>
    </FormKit>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import useAxios from '@/composables/axios'
import useAlerts from '@/composables/useAlerts'
import { trans } from 'laravel-vue-i18n'

const props = defineProps<{
    rsvpId: number | null
}>()

const emit = defineEmits<{
    (e: 'submit')
    (e: 'skip')
}>()

const { error, success } = useAlerts()

const formValues = ref({
    artist: null,
    track: null,
    rsvp_id: props.rsvpId,
})

const errors = ref({})

const onSkip = () => {
    emit('skip')
}

const onSubmit = (playlistSuggestion) => {
    useAxios()
        .then((axios) => {
            axios.post(route('api.playlist_suggestion'), playlistSuggestion)
                .then(() => {
                    success(trans('rsvp.alert_success_playlist'))
                    emit('submit')
                })
                .catch((message) => {
                    console.error(message)
                    error(trans('rsvp.alert_error'))
                    errors.value = message.response.data.errors
                })
        })
}
</script>
