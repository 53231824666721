<template>
    <table>
        <thead>
            <tr>
                <th colspan="2">{{ $t('admin.allergy_table') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(count, allergy) in allergies">
                <td>{{ allergy[0].toUpperCase() + allergy.slice(1) }}</td>
                <td>{{ count }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import useAllergies from '@/composables/allergies'

const { allergies } = useAllergies()
</script>
