<template>
    <layout title="pages.home">
        <ShopButton
            v-if="isWeddingDate"
            label="home.shop_cta"
        />
        <RsvpButton
            v-else
            label="home.rsvp_cta"
        />

        <QuickInfo/>

        <h2>{{ $t('home.heading_time_date') }}</h2>

        <p v-html="$t('home.location', {
            location_url: locationUrl,
            parking_1: parking1Url,
            parking_2: parking2Url,
            parking_3: parking3Url,
        })"/>
        <p>{{ $t('home.time_and_date') }}</p>

        <h2>{{ $t('home.heading_dresscode') }}</h2>
        <p>{{ $t('home.dresscode_free') }}</p>
        <p>{{ $t('home.dresscode_bow') }}</p>

        <h2>{{ $t('home.heading_practical') }}</h2>
        <p v-html="$t('home.roadburn')"/>
        <p>{{ $t('home.food') }}</p>
        <p>{{ $t('home.photography') }}</p>

        <ShopButton v-if="isWeddingDate" />
        <RsvpButton v-else />
    </layout>
</template>

<script setup lang="ts">
import Layout from '@/layout/Layout.vue'
import useEnv from '@/composables/env'
import RsvpButton from '@/components/RsvpButton.vue'
import QuickInfo from '@/components/QuickInfo.vue'
import useWeddingDate from '@/composables/weddingDate'
import ShopButton from '@/components/ShopButton.vue'

const { locationUrl, parking1Url, parking2Url, parking3Url } = useEnv()
const { isWeddingDate } = useWeddingDate()
</script>
