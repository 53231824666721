import { storeToRefs } from 'pinia'
import useRsvpStore from '@/stores/rsvp'
import { computed } from 'vue'
import type { Rsvp } from '@/types/Rsvp'

type AllergyCounts = Record<string, number>

const useAllergies = () => {
    const { rsvps } = storeToRefs(useRsvpStore())

    const allergyMap = {
        '-': null,
        '/': null,
        geen: null,
        nope: null,
        poep: null,
        sinan: null,
        zonlicht: null,
        'allergisch voor justin timberlake': null,
        'het leven': null,
        'lange mensen op de fiets met haast': null,
        'vr*uw': null,
        'suiker rip': 'suiker',
        'en ik hou echt niet van paddenstoelen': 'paddestoelen',
        'avocado but will do dairy because cumin gouda is way too delicious': 'avocado',
        'van alles maar taart werkt! (mits zonder kokos voor sarah)': 'kokos',
        'mensen die houilen omdat ze niet geloven dat de zon weer gaat skainen. en liever geen paprika voor eileen': 'paprika',
    }

    function getAllergyName(allergy: string | null): string | null {
        if (!allergy) {
            return null
        }

        allergy = allergy.trim().toLowerCase()
        if (Object.keys(allergyMap).includes(allergy)) {
            return allergyMap[allergy]
        }

        return allergy
    }

    const allergies = computed(() => rsvps.value
        .map((rsvp: Rsvp): string | null => rsvp.allergies)
        .map((allergies: string | null): Array<string> => allergies ? allergies.split(/[,/]/) : [])
        .flat()
        .map((allergy: string): string | null => getAllergyName(allergy))
        .filter((allergy: string | null): boolean => !!allergy)
        .reduce(
            (allergieCounts: AllergyCounts, allergy: string): AllergyCounts => {
                return {
                    ...allergieCounts,
                    [allergy]: (allergieCounts[allergy] ?? 0) + 1
                }
            },
            {}
        )
    )

    return {
        allergies,
    }
}

export default useAllergies
